import { Link } from "react-router-dom";

const links = [
  { label: "Home", href: "/" },
  { label: "About", href: "/about" },
];

const Navbar = () => {
  return (
    <div className="mt-5 h-10 mx-auto w-3/4 text-lg">
      <div className="container flex flex-row items-center justify-between mx-auto">
        <div className="w-full block"></div>
        <div className="w-full block text-right" id="navbar-default">
          <ul className="flex flex-row-reverse">
            {links.map((l, i) => (
              <li className="block pl-0 ml-5" key={`nav-${i}`}>
                <Link to={l.href}>{l.label}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
