import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedin,
  faGithub,
  faMedium,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const links = [
  { icon: faTwitter, href: "https://twitter.com/ugobriasco" },
  { icon: faLinkedin, href: "https://www.linkedin.com/in/ugobriasco/" },
  { icon: faGithub, href: "https://github.com/ugobriasco" },
  { icon: faMedium, href: "https://medium.com/@ugobriasco/has-recommended" },
  { icon: faInstagram, href: "https://instagram.com/ugobriasco" },
];

const Footer = () => {
  const now = new Date();

  const year = now.getUTCFullYear();

  return (
    <footer className="mx-auto w-3/4 h-10">
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <ul className="flex flex-row">
          {links.map((l, i) => (
            <li className="block pl-0 pr-2" key={`foo-${i}`}>
              <a href={l.href}>
                <FontAwesomeIcon
                  icon={l.icon}
                  width="15px"
                  height="15px"
                  className={`text-gray-800 dark:text-gray-100`}
                />
              </a>
            </li>
          ))}
        </ul>
        <div className="text-xs">
          © {year} |{" "}
          <a className="hover:underline" href="https://ugobriasco.me">
            Ugo Briasco
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
