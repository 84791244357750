import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import { Navbar, Footer } from "../components/navigation";
import Container from "../components/containers/PrimaryContainer";

const a = (text: any, link: string) => (
  <i>
    {" "}
    <a href={link} className="underline">
      {text}
    </a>{" "}
  </i>
);

function Home() {
  const file_name = "About.md";
  const [post, setPost] = useState("");

  useEffect(() => {
    import(`../content/${file_name}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container>
      <Navbar />
      <article className="mb-10 min-h-full sm:w-1/2 mx-5 sm:mx-auto prose dark:prose-invert lg:prose-xl">
        <ReactMarkdown remarkPlugins={[gfm]}>{post}</ReactMarkdown>
      </article>
      <Footer />
    </Container>
  );
}

export default Home;
