const Hero = () => {
  return (
    <div className="mx-auto mt-0 h-screen w-screen flex justify-center items-center">
      <div className="m-5 w-full sm:m-auto sm:w-3/4 h-5/6  flex justify-center items-center bg-[url('../public/images/bg.jpg')] bg-no-repeat bg-cover bg-gray-400 dark:bg-gray-800 bg-center bg-blend-overlay shadow-xl shadow-gray-600 dark:shadow-gray-800">
        <div className="font-extralight">
          <h1 className="text-6xl mb-2 text-center">Hello, I'm Ugo Briasco</h1>
          <p className="text-center">Chasing the soonish with NodeJS</p>
        </div>
      </div>
    </div>
  );
};
export default Hero;
