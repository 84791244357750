import Hero from "../components/Hero";
import { Navbar, Footer } from "../components/navigation";
import Container from "../components/containers/ScreenContainer";

function Home() {
  return (
    <Container>
      <Navbar />
      <Hero />
      <Footer />
    </Container>
  );
}

export default Home;
